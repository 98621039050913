import { useState, useEffect } from 'react';

const useSoundGenerator = () => {
    const [audioContext, setAudioContext] = useState(null);
    const [oscillator, setOscillator] = useState(null);
    const [gainNode, setGainNode] = useState(null);

    useEffect(() => {
        const context = new (window.AudioContext || window.webkitAudioContext)();
        const osc = context.createOscillator();
        const gain = context.createGain();

        osc.connect(gain);
        gain.connect(context.destination);

        setAudioContext(context);
        setOscillator(osc);
        setGainNode(gain);

        osc.start();
        gain.gain.setValueAtTime(0, context.currentTime);

        return () => {
            osc.stop();
            context.close();
        };
    }, []);

    const closeAudioContext = () => {
        if (audioContext) {
            audioContext.close().then(() => {
                setAudioContext(null);
                setOscillator(null);
                setGainNode(null);
            });
        }
    };

    const setFrequency = (frequency) => {
        if (oscillator) {
            oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime);
        }
    };

    const playSound = () => {
        if (gainNode && audioContext.state === 'suspended') {
            audioContext.resume();
        }
        if (gainNode && audioContext.state === 'running') {
            gainNode.gain.cancelScheduledValues(audioContext.currentTime);
            gainNode.gain.setValueAtTime(gainNode.gain.value, audioContext.currentTime);
            gainNode.gain.exponentialRampToValueAtTime(1, audioContext.currentTime + 0.02);
        }
    };

    const stopSound = () => {
        if (gainNode && audioContext.state === 'running') {
            gainNode.gain.cancelScheduledValues(audioContext.currentTime);
            gainNode.gain.setValueAtTime(gainNode.gain.value, audioContext.currentTime);
            gainNode.gain.exponentialRampToValueAtTime(0.001, audioContext.currentTime + 0.02);
        }
    };

    return { setFrequency, playSound, stopSound, closeAudioContext };
};

export default useSoundGenerator;
