import React, { useState, useEffect } from 'react';
import useSoundGenerator from './useSoundGenerator';

const AudioGenerator = () => {
    const [frequency, setFrequency] = useState(440);
    const { setFrequency: setGeneratorFrequency, playSound, stopSound } = useSoundGenerator();

    useEffect(() => {
        setGeneratorFrequency(frequency);
    }, [frequency, setGeneratorFrequency]);

    const handleFrequencyChange = (event) => {
        const newFrequency = parseInt(event.target.value, 10);
        setFrequency(newFrequency);
    };

    const handlePlay = () => {
        playSound();
    };

    const handleStop = () => {
        stopSound();
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>Real-time Audio Generator</h2>
            <p>Adjust the frequency:</p>
            <input
                type="range"
                min="20"
                max="2000"
                value={frequency}
                onChange={handleFrequencyChange}
                style={{ width: '80%' }}
            />
            <p>Current Frequency: {frequency} Hz</p>
            <div>
                <button onClick={handlePlay} style={{ margin: '10px', padding: '10px' }}>Play</button>
                <button onClick={handleStop} style={{ margin: '10px', padding: '10px' }}>Stop</button>
            </div>
        </div>
    );
};

export default AudioGenerator;
