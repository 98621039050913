import React, { useEffect, useState } from "react";
import useSoundGenerator from "./useSoundGenerator";
import { Link } from 'react-router-dom';

const Home = () => {
    const [isPressed, setIsPressed] = useState(false);
    const [startTime, setStartTime] = useState(0);
    const [outputText, setOutputText] = useState('');
    const [lastReleaseTime, setLastReleaseTime] = useState(0);
    const [firstPress, setFirstPress] = useState(true);
    const { setFrequency, playSound, stopSound } = useSoundGenerator();

    const calcMorse = (pressStartTime, releaseTime) => {
        const duration = Math.round((releaseTime - pressStartTime) / 200);
        const interval = Math.round((pressStartTime - lastReleaseTime) / 200);

        let result = '';

        if (!firstPress && interval >= 5) {
            result += '/';
        }

        if (duration === 1) {
            result += '.';
        } else if (duration >= 2 && duration <= 3) {
            result += '-';
        }

        return result;
    };

    const handleMouseDown = () => {
        setIsPressed(true);
        setStartTime(Date.now());
        playSound();
        setFirstPress(false);
    };

    const handleMouseUp = () => {
        setIsPressed(false);
        const releaseTime = Date.now();
        stopSound();
        const newMorse = calcMorse(startTime, releaseTime);
        setOutputText(prev => prev + newMorse);
        setLastReleaseTime(releaseTime);
    };

    const handleKeyDown = (event) => {
        if (event.code === 'Space') {
            event.preventDefault();
            if (!isPressed) {
                setIsPressed(true);
                setStartTime(Date.now());
                playSound();
                setFirstPress(false);
            }
        }
    };

    const handleKeyUp = (event) => {
        if (event.code === 'Space') {
            event.preventDefault();
            if (isPressed) {
                setIsPressed(false);
                const releaseTime = Date.now();
                stopSound();
                const newMorse = calcMorse(startTime, releaseTime);
                setOutputText(prev => prev + newMorse);
                setLastReleaseTime(releaseTime);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [isPressed, lastReleaseTime]);

    const buttonStyle = {
        padding: '20px',
        fontSize: '20px',
        background: isPressed ? '#ffc0cb' : '#dcdcdc',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    const textareaStyle = {
        width: '80%',
        fontSize: '18px',
        padding: '10px',
        marginTop: '20px',
    };

    const morseCodeTable = [
        { character: 'A', code: '.-' },
        { character: 'B', code: '-...' },
        { character: 'C', code: '-.-.' },
        { character: 'D', code: '-..' },
        { character: 'E', code: '.' },
        { character: 'F', code: '..-.' },
        { character: 'G', code: '--.' },
        { character: 'H', code: '....' },
        { character: 'I', code: '..' },
        { character: 'J', code: '.---' },
        { character: 'K', code: '-.-' },
        { character: 'L', code: '.-..' },
        { character: 'M', code: '--' },
        { character: 'N', code: '-.' },
        { character: 'O', code: '---' },
        { character: 'P', code: '.--.' },
        { character: 'Q', code: '--.-' },
        { character: 'R', code: '.-.' },
        { character: 'S', code: '...' },
        { character: 'T', code: '-' },
        { character: 'U', code: '..-' },
        { character: 'V', code: '...-' },
        { character: 'W', code: '.--' },
        { character: 'X', code: '-..-' },
        { character: 'Y', code: '-.--' },
        { character: 'Z', code: '--..' },
        { character: '0', code: '-----' },
        { character: '1', code: '.----' },
        { character: '2', code: '..---' },
        { character: '3', code: '...--' },
        { character: '4', code: '....-' },
        { character: '5', code: '.....' },
        { character: '6', code: '-....' },
        { character: '7', code: '--...' },
        { character: '8', code: '---..' },
        { character: '9', code: '----.' },
    ];

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <button
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                style={buttonStyle}
            >
                press
            </button>
            <div style={{ marginTop: '20px' }}>
                <textarea
                    rows={5}
                    cols={50}
                    readOnly
                    value={outputText}
                    style={textareaStyle}
                />
            </div>
            <div style={{ marginTop: '20px', textAlign: 'left' }}>
                <h3>Morse Code Reference</h3>
                <table style={{ margin: 'auto' }}>
                    <tbody>
                        {morseCodeTable.map((item, index) => (
                            <tr key={index}>
                                <td>{item.character}</td>
                                <td>{item.code}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Home;
