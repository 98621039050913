import React, {useEffect, useRef, useState} from "react";
import {BrowserRouter as Router, Route, Link, Routes} from "react-router-dom";

import AudioGenerator from './AudioGenerator'
import Home from './Home'


const App = () => {
    return (
        <Router>
            <div>
                <nav style={{textAlign: 'center', padding: '10px', background: '#f0f0f0'}}>
                    <ul style={{listStyleType: 'none', padding: 0}}>

                        <li style={{display: 'inline', marginRight: '10px'}}>
                            <Link to="/">Home</Link>
                        </li>
                        <li style={{display: 'inline',marginRight: '10px'}}>
                            <Link to={"/audio"}>generator</Link>
                        </li>

                    </ul>
                </nav>
                <Routes>
                    <Route path='/' element={<Home/>}></Route>
                    <Route path="/audio" element={<AudioGenerator/>}></Route>
                </Routes>
            </div>
        </Router>
    )
}


export default App;
